.sidenav {
  display: none;
 }

.gapPadding {
  height: 0px;
}

 .wrapper {
   width: 100%;
 }

.mainCode {
  color: var(--text-primary);
  background-color: var(--background);
  transition: background-color 0.5s ease-out;
  width: fit-content;
  letter-spacing: 2px;
}

.me {
  font-family: Roboto;
}

/* buat font antar huruf jauhan */

.info {
  color: goldenrod;
  text-align: start;
  font-size: 100px;
}

.info2 {
  text-align: start;
  font-size: 100px;
}

.infoSocialMedia {
  align-items: flex-start;
  font-size: 50px;
  font-family: LibreFranklin;
}

.socialMedia {
  font-size: 70px;
  font-family: LibreFranklin;
  padding-top: 10px;
}

.socialmediaInfo {
  font-size: 25px;
  font-family: LibreFranklin;
  padding-left: 1%;
}

.researchInterest {
  padding-top: 50px;
  font-size: 45px;
  font-family: LibreFranklin;
}

.researchInterestInfo {
  padding-top: 10px;
  font-size: 30px;
  font-family: LibreFranklin;
}

.title {
  font-size: 60px;
  justify-content: start;
  font-family: Roboto;
}

.subTitle {
  font-weight: bold;
  font-size: 35px;
  font-family: LibreFranklin;
  /* padding-left: 50px; */
}

.subTitle2 {
  font-weight: bold;
  font-size: 30px;
  font-family: LibreFranklin;
  /* padding-left: 50px; */
}

.Information {
  font-size: 30px;
  font-family: LibreFranklin;
  /* padding-left: 70px; */
}

.educationDate{
  font-weight: bold;
  font-size: 30px;
  margin-left: auto;
  font-family: LibreFranklin;
  grid-column-start: 6;
  grid-column: span 4;
}

.educationGPA{
  font-size: 30px;
  margin-left: auto;
  font-family: LibreFranklin;
  grid-column-start: 6;
  grid-column: span 4;
}

.skillsInfo {
  padding-top: 5px;
  font-size: 30px;
  font-family: LibreFranklin;
  font-weight: bold;
  /* padding-left: 50px; */
  padding-top: 17px;
}

.skillsDesc {
  padding-top: 5px;
  font-size: 30px;
  font-family: LibreFranklin;
  padding-top: 17px;
  padding-left: 40%;
}



@media only screen and (min-width: 1080px) {
  
  .mainCode {
    padding-left: 270px;
    color: var(--text-primary);
    background-color: var(--background);
    transition: background-color 0.5s ease-out;
    letter-spacing: 3px;
  }
  
  .stickyNav {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .gapPadding {
    height: 100px;
  }

  .wrapper {
    width: 100%;
  }
  
  .sidenav {
    display:block;
    height: 100%;
    width: 370px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    overflow-x:inherit;
    padding-top: 5%;
    padding-left: 12px;
    color: var(--text-primary);
    background-color: var(--background);
    transition: background-color 0.5s ease-out
  }
  
  /* buat font antar huruf jauhan */
  
  .sidenav a  {
    padding: 6px 8px 6px 16px;
    text-decoration: none;
    font-size: 25px;
    display: block;
    align-items: center;
    color: var(--text-primary);
    font-family: Roboto;
    font-weight: bold;
  }
  
  .sidenav b {
    padding: 6px 8px 6px 16px;
    text-decoration: none;
    font-size: 25px;
    display: block;
    align-items: center;
    padding-top: 10px;
    color: var(--text-primary);
  }
  
  .sidenav a:hover {
    color: #FFF4e4;
  }
  
  .uiMode {
    color: black;
    font-size: 40px;
    padding-left: 43%;
  }
  
  .uiMode:hover {
    color: #FFF4e4;
  }
  
  .navigation {
    font-size: 40px;
    color: #7cdc8c;
    cursor:default;
  }
  
  .navigationTop {
    margin-top: 50px;
    font-size: 40px;
    color: #7cdc8c;
    cursor:default;
  }
  
  .navigationInfo {
    text-align: center;
  }
  
  .me {
    padding-top: 10%;
    padding-left: 100px;
    font-family: Roboto;
  }
  
  .mypicture {
    border-radius: 100%;
    width: 310px;
    height: 310px;
    align-items: center;
  }
  
  .info {
    color: goldenrod;
    text-align: start;
    font-size: 100px;
    padding-top: 40px;
  }

  .info2 {
    text-align: start;
    font-size: 100px;
  }
  
  .infoSocialMedia {
    align-items: center;
    font-size: 30px;
    font-family: LibreFranklin;
  }
  
  .socialMedia {
    white-space: nowrap;
    font-size: 50px;
    font-family: LibreFranklin;
    padding-top: 10px;
    display: block;
  }
  
  .socialmediaInfo {
    font-size: 25px;
    font-family: LibreFranklin;
    /* padding-top: 20px;  */
    padding-left: 3%;
    display: block;
  }
  
  .researchInterest {
    padding-top: 50px;
    font-size: 35px;
    font-family: LibreFranklin;
  }
  
  .researchInterestInfo {
    padding-top: 10px;
    font-size: 20px;
    font-family: LibreFranklin;
    width: 100%;
  }
  
  .pageSection {
    padding-top: 15%;
    padding-left: 100px;
  }
  
  .title {
    font-size: 60px;
    justify-content: start;
    font-family: Roboto;
    padding-bottom: 5%;
  }
  
  .subTitle {
    font-weight: bold;
    font-size: 25px;
    font-family: LibreFranklin;
    padding-left: 50px;
  }
  
  .subTitle2 {
    font-weight: bold;
    font-size: 20px;
    font-family: LibreFranklin;
    padding-left: 50px;
  }
  
  .Information {
    font-size: 25px;
    font-family: LibreFranklin;
    padding-left: 70px;
  }
  
  .educationDate{
    font-weight: bold;
    font-size: 25px;
    margin-left: auto;
    font-family: LibreFranklin;
  }
  
  .educationGPA{
    font-size: 25px;
    margin-left: auto;
    font-family: LibreFranklin;
  }
  
  .project{
    padding-left: 100px;
  }
  
  .skillsInfo {
    padding-top: 5px;
    font-size: 20px;
    font-family: LibreFranklin;
    font-weight: bold;
    padding-left: 50px;
    padding-top: 17px;
  }
  
  .skillsDesc {
    padding-top: 5px;
    font-size: 20px;
    font-family: LibreFranklin;
    padding-top: 17px;
    padding-left: 40%;
    display: flex;
  }
}