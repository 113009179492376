@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Roboto';
  src: url('../font/Roboto-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'LibreFranklin';
  src: url('../font/LibreFranklin-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


[data-theme='light'] {
  --background: #FFF4e4;
  --text-primary: #232528;
}

[data-theme='dark'] {
  --background: #232528;
  --text-primary: #FFF4e4;
}

[data-theme-sidenav='light']{
 --background:  #0f5257;
 --text-primary: black;
}

[data-theme-sidenav='dark']{
  --background: #89937c;
  --text-primary: #0f5257;
}

@media only screen and (min-width: 1400px) {

.wrapper {
  width: inherit;
}

.mainCode {
  padding-left: 270px;
  color: var(--text-primary);
  background-color: var(--background);
  transition: background-color 0.5s ease-out;
  width: 100%;
  letter-spacing: 3px;
}

.stickyNav {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.sidenav {
  display:block;
  height: 100%;
  width: 370px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  overflow-x:inherit;
  padding-top: 5%;
  padding-left: 12px;
  color: var(--text-primary);
  background-color: var(--background);
  transition: background-color 0.5s ease-out
}

/* buat font antar huruf jauhan */

.sidenav a  {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 25px;
  display: block;
  align-items: center;
  color: var(--text-primary);
  font-family: Roboto;
  font-weight: bold;
}

.sidenav b {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 25px;
  display: block;
  align-items: center;
  padding-top: 10px;
  color: var(--text-primary);
}

.sidenav a:hover {
  color: #FFF4e4;
}

.uiMode {
  color: black;
  font-size: 40px;
  padding-left: 43%;
}

.uiMode:hover {
  color: #FFF4e4;
}

.navigation {
  font-size: 40px;
  color: #7cdc8c;
  cursor:default;
}

.navigationTop {
  margin-top: 50px;
  font-size: 40px;
  color: #7cdc8c;
  cursor:default;
}

.navigationInfo {
  text-align: center;
}

.me {
  padding-top: 13%;
  padding-left: 100px;
  font-family: Roboto;
}

.mypicture {
  border-radius: 100%;
  width: 310px;
  height: 310px;
  align-items: center;
}

.info {
  color: goldenrod;
  text-align: start;
  font-size: 100px;
}

.info2 {
  text-align: start;
  font-size: 100px;
  /* padding-top: 40px; */
}

.infoSocialMedia {
  align-items: center;
  font-size: 30px;
  font-family: LibreFranklin;
}

.socialMedia {
  white-space: nowrap;
  font-size: 50px;
  font-family: LibreFranklin;
  /* padding-top: 10px ; */
}

a:hover {
    cursor:default;
}

.socialmediaInfo {
  font-size: 25px;
  font-family: LibreFranklin;
  /* padding-top: 20px;  */
  padding-left: 1%;
}

.researchInterest {
  padding-top: 50px;
  font-size: 35px;
  font-family: LibreFranklin;
}

.researchInterestInfo {
  padding-top: 10px;
  font-size: 20px;
  font-family: LibreFranklin;
}

.pageSection {
  padding-top: 13%;
  padding-left: 100px;
}

.title {
  font-size: 60px;
  justify-content: start;
  font-family: Roboto;
  padding-bottom: 5%;
}

.subTitle {
  font-weight: bold;
  font-size: 25px;
  font-family: LibreFranklin;
  padding-left: 50px;
}

.subTitle2 {
  font-weight: bold;
  font-size: 20px;
  font-family: LibreFranklin;
  padding-left: 50px;
}

.Information {
  font-size: 25px;
  font-family: LibreFranklin;
  padding-left: 70px;
}

.InformationGap {
  padding-top: 20px;
}

.educationDate{
  font-weight: bold;
  font-size: 25px;
  margin-left: auto;
  font-family: LibreFranklin;
  grid-column-start: 5;
  grid-column: span 4;
}

.educationGPA{
  font-size: 25px;
  margin-left: auto;
  font-family: LibreFranklin;
  grid-column-start: 5;
  grid-column: span 4;
}

.project{
  padding-left: 100px;
}

.skillsInfo {
  padding-top: 5px;
  font-size: 20px;
  font-family: LibreFranklin;
  font-weight: bold;
  padding-left: 50px;
  padding-top: 17px;
}

.skillsDesc {
  padding-top: 5px;
  font-size: 20px;
  font-family: LibreFranklin;
  padding-top: 17px;
  padding-left: 10%;
  display: flex;
}
.gapPadding {
  height: 400px;
}

} /* screen laptop & computer */

